import Player from '@vimeo/player';

(function($, document, window) {

    // DOM Ready
    $(document).ready(() => {
        // Related Classes Handling
        if ($(".related-exsists")[0]){
            $(".related-doesnt-exsist").hide();
        } else {
            // Handle absence if needed
        }

        // Assign ID to first page section excluding footer
        $(".page-section:not([id^='page-footer'])").first().attr("id", "page-main");

        var $body = $('body');

        // Remove inline styles on load
        $(window).on('load', function(){
            $(".crm-gencontact").removeAttr('style');
        });

        // Headroom Initialization on load and resize
        $(window).on('load resize', function () {
            $('#back-rfp').headroom('destroy').headroom({
                offset: window.innerHeight - 100,
                tolerance: 5
            });

            $('#page-header').headroom('destroy').headroom({
                offset: window.innerHeight - 150,
                tolerance: 5
            });

            // Adjust vertical titles
            $('section > .inner > h2').each(function () {
                var height = $(this).height();
                var parent = $(this).parent();

                if (parent.height() < height) {
                    parent.css('min-height', height + 'px');
                }
            });
        });

        // Vertical Tabs Toggle
        var verticalTabs = $('.vertical-tabs');

        if (verticalTabs.length > 0) {        
            var positions = verticalTabs.find('li');
            positions.on('click', function () {
                if (verticalTabs.hasClass('manual-mode')) {
                    $(this).toggleClass("active");
                } else {
                    positions.removeClass("active");    
                    $(this).addClass("active");
                }
            });
        }

        // Remove Outline Styling
        var styleText = '::-moz-focus-inner{border:0 !important;}:focus{outline: none !important;';
        var unfocus_style = document.createElement('style');

        window.unfocus = function(){
            document.head.appendChild(unfocus_style);

            document.addEventListener('mousedown', function(){
                unfocus_style.innerHTML = styleText+'}';
            });
            document.addEventListener('keydown', function(){
                unfocus_style.innerHTML = '';
            });
        };

        unfocus.style = function(style){
            styleText += style;
        };

        unfocus();

        // URL Hash Handling
        let url = location.href.replace(/\/$/, "");

        if (location.hash) {
            $('html, body').animate({
                scrollTop: $('#myTab').offset().top - 220
            }, 1000, 'swing');

            const hash = url.split("#");
            $('#myTab a[href="#'+hash[1]+'"]').tab("show");
            url = location.href.replace(/\/#/, "#");
            history.replaceState(null, null, url);
            setTimeout(() => {
                $(window).scrollTop(0);
            }, 400);
        } 

        $('a[data-bs-toggle="tab"]').on("click", function() {
            let newUrl;
            const hash = $(this).attr("href");
            
            if(hash == "#tab-0") {
                newUrl = url.split("#")[0];
            } else {
                newUrl = url.split("#")[0] + hash;
            }
            history.replaceState(null, null, newUrl);
        });

        // Toggle EFW Body
        $('.efw-button').on('click', function () {
            $('.efw-body').toggleClass('open');
            $('.efw-button .meta').toggleClass('open');
        });

        // Audio Controls
        $('.media-section .controls button').on('click', function () {
            var $button = $(this);
            var $section = $button.closest('.media-section');
            var audio = $section.find('audio')[0];

            if ($button.hasClass('play')) {
                $button.removeClass('play').addClass('pause');
                audio.play();
            } else {
                $button.removeClass('pause').addClass('play');
                audio.pause();
            }
        });

        $('.media-section audio').on('timeupdate', function () {
            var $progress = $(this).siblings('.poster').find('.controls progress');
            $progress.attr('value', (this.currentTime / this.duration) * 100);
        });

        // Set Background Images
        setBackgroundImage($('.background-image'));

        // Close Collapse
        $('a.close').on('click', function(){
            $(this).closest('.collapse').removeClass('show');
        });

        // Scroll to People Section
        $('a.background-image').on('click', function(){
            $('html, body').animate({
                scrollTop: $(".people-section").offset().top - 100
            }, 1000);
        });

        // Menu Toggle
        var $menuButton =  $('.nav-button');
        $menuButton.on('click', function () {
            $body.toggleClass('menu-open');
        });

        $('#page-nav').on('click', function () {
            $body.removeClass('menu-open');
        });
         
        $('#page-nav .inner').on('click', function (event) {
            event.stopPropagation();
        });

        $('#main-nav a').on('click', function () {
            $(this).parent('li').toggleClass('open');
        });

        // AJAX Form Submission
        $('#filter').on('change', function(){
            var filter = $('#filter');

            $.ajax({
                url: filter.attr('action'),
                data: filter.serialize(),
                type: filter.attr('method'),

                beforeSend: function(xhr){
                    filter.find('button').text('Processing...'); 
                    $('#response').html('<div class="inner narrow"><h2>Loading...</h2></div>'); 
                },
                success: function(data){
                    filter.find('button').text('Reset'); 
                    $('#response').html(data); 
                }
            });
            return false;
        });

        // Work Section Controls
        var $work = $('#work');
        var $switch = $work.find('.radio-switch');

        $('.sort').on('change', function() {
            var $val = getSwitchValue();
        
            if ($val == '20') {
                switchRadio();
            }
        });

        $switch.on('click', function(e) {
            switchRadio();

            var $val = getSwitchValue();

            if ($val == '20') {
                clearFilters();
            }
        });
      
        function getSwitchValue() {
            return $switch.find('input[type=radio]:checked').val();
        }

        function clearFilters() {
            $("option[value=4], option[value=11]").prop('selected', 'selected').change();

            var $val = getSwitchValue();

            if ($val == '24') {
                switchRadio();
            }
        }

        function clearFeedFilters() {
            $("option[value=all], option[value=selectyear]").prop('selected', 'selected').change();
        }

        $('.reset-feed').on('click', function() {
            clearFeedFilters();
        });

        $('.reset').on('click', function() {
            var $val = getSwitchValue();
            $work.find('select').val();
            if ($val == '24') {
                clearFilters();
            }
        });

        function switchRadio() {
            var $radios = $switch.find('input[type=radio]');
            $switch.toggleClass('switched');
            $radios.prop('checked', false);
                
            if ($switch.hasClass('switched')) {
                $radios.last().prop('checked', true).change();
                turnon();
            } else {
                $radios.first().prop('checked', true).change();
                turnoff();
            }
        }

        $("a.arrow").on('click', function(e) {
            e.preventDefault();
            var aid = $(this).attr("href");
            $('html, body').animate({scrollTop: $(aid).offset().top - 198}, 'slow');
        });

        function turnon(){
            $('#work > div.inner').attr('class','dev-work-group inner narrow').delay(800);
            $('#updatelist').attr('class','boxes boxes-4 boxes-square hover').delay(800);
            $('#updatelist li').attr('class','dev-work-item').delay(800);
        }

        function turnoff(){
            $('#work > div.inner').attr('class','inner narrow');
            $('#updatelist').attr('class','boxes boxes-3 boxes-square featured hover');
            $('#updatelist li').attr('class','none');
        }

        // Background Image Setup
        function setBackgroundImage(jList) {
            jList.each((_index, element) => {
                const $element = $(element);
                const $inner = $element.find('> .inner-image');
                const $image = $element.find('> img');
                const backgroundImageUrl = `url(${$image.attr('src')})`;
                $inner.css('background-image', backgroundImageUrl);
                $image.hide();
            });
        }

        // Prevent Default Functions
        function preventDefault(e) {
            e = e || window.event;
            if (e.preventDefault)
                e.preventDefault();
            e.returnValue = false;  
        }
          
        function preventDefaultForScrollKeys(e) {
            if (keys[e.keyCode]) {
                preventDefault(e);
                return false;
            }
        }

        function disableScroll() {
            document.addEventListener('wheel', preventDefault, {passive: false});
            window.onwheel = preventDefault;
            window.onmousewheel = document.onmousewheel = preventDefault;
            window.ontouchmove  = preventDefault;
            document.onkeydown  = preventDefaultForScrollKeys;
        }

        function enableScroll() {
            document.removeEventListener('wheel', preventDefault, {passive: false});
            window.onmousewheel = document.onmousewheel = null; 
            window.onwheel = null; 
            window.ontouchmove = null;  
            document.onkeydown = null;  
        }

        // Initialize Vimeo Player
        var iframe = document.getElementById('large-video-sound');
        var player;

        if (iframe) {
            player = new Player(iframe, {
                id: iframe.getAttribute('data-video-id'),
                width: 640
            });

            // Play Button
            $("#play-button").on('click', function(){
                $(".video").toggleClass('hide');
                $(".video-sound").toggleClass('show');
                $("#video-controls").toggleClass('show');
                $(this).toggleClass('hide');
                $('.imagetitle').hide();
                $('#master-video .overlay').hide();
                $('#master-video .arrow').hide();

                player.play().catch(function(error) {
                    console.error('Error playing the video:', error);
                });
            });

            // Stop Button
            $(".stop").on('click', function(){
                $(".video").toggleClass('hide');
                $(".video-sound").toggleClass('show');
                $("#video-controls").toggleClass('show');
                $('#play-button').toggleClass('hide');
                $('.imagetitle').show();
                $('#master-video .overlay').show();
                $('#master-video .arrow').show();
                $('.play').removeClass('show').addClass('hide');
                $('.pause').removeClass('hide').addClass('show');

                player.pause().then(function() {
                    return player.setCurrentTime(0);
                }).catch(function(error) {
                    console.error('Error stopping the video:', error);
                });
            });

            // Pause Button
            $(".pause").on('click', function(){
                $(this).removeClass('show').addClass('hide');
                $('.play').removeClass('hide').addClass('show');

                player.pause().catch(function(error) {
                    console.error('Error pausing the video:', error);
                });
            });

            // Play Toggle Button
            $(".play").on('click', function(){
                $(this).removeClass('show').addClass('hide');
                $('.pause').removeClass('hide').addClass('show');

                player.play().catch(function(error) {
                    console.error('Error playing the video:', error);
                });
            });

            // Small Play Button
            $("#sm-play-button").on('click', function(){
                player.play().catch(function(error) {
                    console.error('Error playing the video:', error);
                });
            });

            // Vimeo Player Events
            player.on('play', function() {
                console.log('Vimeo Player: Video is playing');
            });

            player.on('pause', function() {
                console.log('Vimeo Player: Video is paused');
            });

            player.on('ended', function() {
                console.log('Vimeo Player: Video has ended');
            });
        } else {
            console.warn('Element #large-video-sound iframe not found.');
        }

    }); // End of document.ready

    // Outline removal and scroll prevention functions are already handled above.

}(jQuery, document, window));

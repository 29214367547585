(function($, window) {
  

    // This is for the careers and drop downs, EP
    // var resizeTimer;
    // $(window).on('load resize', function(e) {
    //   clearTimeout(resizeTimer);
    //   resizeTimer = setTimeout(function() {
    //     if ($(window).width() < 991) {
    //             $('#careers > li > a').on('click', function (event){
    //                   event.preventDefault();
    //                         $([document.documentElement, document.body]).animate({
    //                         scrollTop: $('#careers li.active').offset().top - 100
    //                         }, 200);	
    //             });    

    //             $('ul.narrow.vertical-tabs li > a').on('click', function (event){
    //                 event.preventDefault();
    //                       $([document.documentElement, document.body]).animate({
    //                       scrollTop: $('ul.narrow.vertical-tabs li').offset().top - 100
    //                       }, 200);	
    //           });     
    //     }       
    //   }, 250);
    // });
    
    // process lines
    $(window).on('load resize', function (e) {

      var x1 = 0;
      var y1 = 0;
      var x2 = 0;
      var y2 = 0;
      var $module = $('.process-section');

      $module.closest('.fl-row-content-wrap').css('position','unset');

      $module.find('.line').remove();

      $module.find('li').each(function (index, element) {

        var $image = $(element).find('img');

        var offset = $image.offset();

        var width = $image.width();
    
        var height = $image.height();

        var x = offset.left + width / 2;

        var y = offset.top + height / 2;

        console.log(index);
        if (index === 0) {
          x1 = x;
          y1 = y;
        } else {
          x2 = x;
          y2 = y;
          
          createLine(x1, y1, x2, y2, $module);

          x1 = x;
          y1 = y;
        }
      });
    });

    function createLine(x1, y1, x2, y2, $module) {
      // count distance
      var distance = Math.sqrt( ( (x1-x2) * (x1-x2) ) + ( (y1-y2) * (y1-y2) ) );



      var xMid = (x1+x2) / 2;
      var yMid = (y1+y2) / 2;

      console.log(yMid);

      // angle
      var angleInRadian = Math.atan2(y1 - y2, x1- x2);
      var angleInDegrees = (angleInRadian * 180) / Math.PI;

      // update css
      $('<span class="line dingus">').appendTo($module).css({
        width: distance,
        top: yMid,
        left: xMid - (distance / 2),
        transform: "rotate(" + angleInDegrees + "deg)"
      });
    }
}(jQuery, window));
